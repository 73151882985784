import Vue from 'vue'

import Router from 'vue-router'

import GameMain from './views/game/GameMain';
import GameRegister from './views/game/GameRegister';
import GameReset from './views/game/GameReset';

import WxPayQrCode from './views/webview/WxPayQrCode';
import SdkMain from './views/sdk/SdkMain';
import SdkPassword from './views/sdk/SdkPassword';
import SdkKf from './views/sdk/SdkKf';
import SdkAccount from './views/sdk/SdkAccount';
import SdkRecharge from './views/sdk/SdkRecharge';
import HomeMain from './views/home/Main';
import GameList from './views/home/GameList';
import HomeIntro from './views/home/Intro';
import HomeKf from './views/home/Kf';
import HomeApp from './views/home/DownloadApp';
import HomeDetail from './views/home/Detail';
import Test from './views/game/test';

import WeixinJSPay from './views/open/WeixinJSPay';
import GameSpeedH5 from "@/views/home/GameSpeedH5.vue";

Vue.use(Router)

export default new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/h5game/*',
      name: 'GameMain',
      component: GameMain
    },
    {
      path: '/h5/register',
      name: 'GameRegister',
      component: GameRegister
    },
    {
      path: '/h5/reset',
      name: 'GameReset',
      component: GameReset
    },
    {
      path: '/wxPayQrCode',
      name: 'WxPayQrCode',
      component: WxPayQrCode
    },
    {
      path: '/sdk/main',
      name: 'SdkMain',
      component: SdkMain
    },
    {
      path: '/sdk/password',
      name: 'SdkPassword',
      component: SdkPassword
    },
    {
      path: '/sdk/kf',
      name: 'SdkKf',
      component: SdkKf
    },
    {
      path: '/sdk/account',
      name: 'SdkAccount',
      component: SdkAccount
    },
    {
      path: '/sdk/recharge',
      name: 'SdkRecharge',
      component: SdkRecharge
    },
    {
      path: '/index',
      name: 'HomeMain',
      component: HomeMain
    },
    {
      path: '/home/gameList',
      name: 'GameList',
      component: GameList
    },
    {
      path: '/home/intro',
      name: 'HomeIntro',
      component: HomeIntro
    },
    {
      path: '/home/kf',
      name: 'HomeKf',
      component: HomeKf
    },
    {
      path: '/home/app',
      name: 'HomeApp',
      component: HomeApp
    },
    {
      path: '/detail/*',
      name: 'HomeDetail',
      component: HomeDetail
    },
    {
      path: '/weixinJSPay',
      name: 'WeixinJSPay',
      component: WeixinJSPay
    },
    {
      path: '/game/test',
      name: 'Test',
      component: Test
    },
    {
      path: '/game/speed',
      name: 'GameSpeedH5',
      component: GameSpeedH5
    },
  ]
})
