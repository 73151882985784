<template>
    <!--<div class="all-panel">
        <van-button v-if="showResult" type="primary" style="margin-top: 300px">{{ text }}</van-button>
    </div>-->

    <div class="main-page">
        <div style="width: 100%;height: 70px; background-color: #7a69be">
            <div style="width: 100%; max-width: 300px; height: 70px; display: flex;align-items: center;margin: 0 auto;">
                <img src="../../assets/logo-radius.png" style="width: 40px;height: 40px;">
                <span style="font-size: 20px;font-weight: bold;margin-left: 26px;color: #ffffff">星游纪元</span>
            </div>
        </div>

        <div class="scan-center">
            <img src="../../assets/scan_code_img.png" style="width: 60px;height: 60px;">
            <span style="margin-top: 20px;">请继续完成支付操作</span>
        </div>
        <div v-if="payResult === 1" class="scan-center">
            <img src="../../assets/success_icon.png" style="width: 50px;height: 50px;">
            <span style="margin-top: 20px;">支付成功</span>
        </div>
        <div v-if="payResult === 2" class="scan-center">
            <img src="../../assets/fail_icon.png" style="width: 50px;height: 50px;">
            <span style="margin-top: 20px;">支付失败，请联系客服</span>
        </div>
    </div>
</template>

<script>
    import {Toast} from "vant";
    import {request} from '@/api/request'


    export default {
        name: 'WeixinJSPay',
        data() {
            return {
                payResult: 0,
            }
        },
        mounted() {
            let url = window.location.href;
            let code = url.split("?")[1].split("#")[0].split("&")[0].split("=")[1];
            let orderId = url.split("?")[1].split("#")[0].split("&")[1].split("=")[1];
            this.onBridgeReady(code, orderId);
        },
        methods: {
            onBridgeReady(code, orderId) {

                let options = {
                    url: '/open/weixinJSPay',
                    data: {
                        code: code,
                        orderId: orderId
                    }
                }
                request(options).then(res => {
                    if (res.data.success) {
                        WeixinJSBridge.invoke('getBrandWCPayRequest',
                            {
                                "appId": res.data.data.appId,     //公众号ID，由商户传入
                                "timeStamp": res.data.data.time,     //时间戳，自1970年以来的秒数
                                "nonceStr": res.data.data.random,      //随机串
                                "package": "prepay_id=" + res.data.data.prepayId,
                                "signType": "RSA",
                                "paySign": res.data.data.sign
                            },
                            res => {
                                if (res.err_msg === "get_brand_wcpay_request:ok") {
                                    this.payResult = 1;
                                } else {
                                    this.payResult = 2;
                                }
                            });
                    } else {
                        Toast(res.data.error);
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .main-page {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        background-color: #f8f8f8;
    }

    .scan-center {
        width: 80%;
        margin: 20px auto 0;
        background-color: #ffffff;
        border-radius: 5px;
        padding: 15px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #8a8a8a;
        font-size: 13px;
    }
</style>
