<template>
    <div class="bg-img">
        <div class="main-page">

            <div style="width: 100%;text-align: left;margin: 90px 20px 0;">
                <img src="../assets/logo/logo-main.png" style="width: 150px;">
            </div>

            <div class="main-border">

                <van-form @submit="onSubmit" ref="submitForm">

                    <div style="font-size: 18px;font-weight: bold;text-align: left;margin-bottom: 15px;">
                        <span>欢迎登录</span>
                    </div>

                    <div style="text-align: left;font-size: 13px;margin-bottom: 5px;">账号</div>
                    <van-field style="color: #F18F25" placeholder="请输入账号/手机号" class="border-input" v-model="account"
                               clearable>
                        <template #button>
                            <van-icon name="arrow-down" @click="selectOtherAccount"/>
                        </template>
                    </van-field>

                    <div style="text-align: left;font-size: 13px;margin-bottom: 5px;margin-top: 20px;">登录密码</div>
                    <van-field type="password" style="color: #F18F25;" placeholder="请输入登录密码" class="border-input"
                               v-model="password" clearable/>

                    <van-popup v-model="showPicker" round position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="accountColumns"
                                @cancel="showPicker = false"
                                @confirm="pickAccount"
                                @change="onChange"
                        />
                    </van-popup>

                    <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 15px;font-size: 12px;padding: 0 5px;">

                        <van-checkbox v-model="rememberPassword" icon-size="14px" shape="square" checked-color="#7a69be">
                            <span style="color: #666666;font-size: 12px;">记住密码</span>
                        </van-checkbox>

                        <span @click="toForgetPage" style="color: #888888;cursor: pointer;user-select: none;">忘记密码?</span>
                    </div>

                    <div class="box-button">
                        <van-button type="info" round block native-type="submit" :loading="loading"
                                    style="height: 35px;font-weight: bold;margin-top: 40px;"
                                    loading-text="登录中" color="#7a69be">
                            登 录
                        </van-button>
                        <div class="text-button">还没有帐号？<span @click="toRegisterPage" style="color: #7a69be;cursor: pointer;user-select: none;">立即注册</span>
                        </div>
                    </div>
                </van-form>

            </div>
        </div>
    </div>
</template>

<script>

    import {request} from '@/api/request'
    import crypto from '@/utils/crypto.js';
    import qiliLogo from "@/assets/qiliLogo.png";
    import accountIcon from "@/assets/account.png";
    import passwordIcon from "@/assets/password.png";

    import {Toast} from 'vant'

    export default {
        name: 'login',
        data() {
            return {
                qiliLogo,
                accountIcon,
                passwordIcon,
                account: '',
                password: '',
                rememberPassword: false,
                accountPattern: /^[a-zA-Z0-9_]{6,12}$/,
                passwordPattern: /^[a-zA-Z0-9~!@#$%^&*()_+.]{6,20}$/,
                loading: false,
                channelId: '',
                gameId: '',
                accountColumns: [],
                showPicker: false,
                userList: [],
                currentIndex: 0,
                from: '',
            };
        },
        mounted() {
            this.channelId = localStorage.getItem('channelId');
            this.gameId = localStorage.getItem('gameId');

            let isRemember = localStorage.getItem('rememberPassword');
            if (isRemember === "true") {
                this.rememberPassword = true;
                this.account = localStorage.getItem('username');
                this.password = crypto.decrypt(localStorage.getItem('password'));
            }

            this.userList = JSON.parse(localStorage.getItem('userList')) || [];
            if (this.userList.length > 0) {
                this.userList.forEach((v, i) => {
                    this.accountColumns.push(v.u);
                })
            }
        },
        methods: {
            back() {
                this.$router.back();
            },
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }
                this.loading = true;
                let options = {
                    url: "/h5/login",
                    data: {
                        device: 'H5',
                        gameId: this.gameId,
                        username: this.account,
                        password: crypto.encrypt(this.password)
                    }
                };
                request(options).then(res => {
                    this.loading = false;
                    if (res.data.success) {
                        localStorage.setItem('token', res.data.data.token);
                        localStorage.setItem('user', JSON.stringify(res.data.data));
                        //localStorage.setItem('loginUrl', res.data.data.loginUrl);

                        this.handlerRememberPassword();
                        this.$parent.checkRealName();
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            checkForm() {
                let ok = 'ok';
                if (!this.account) {
                    ok = "请输入帐号"
                } else if (!this.password) {
                    ok = "请输入密码";
                } else if (!this.accountPattern.test(this.account)) {
                    ok = "帐号为6-12位数字字母下划线";
                } else if (!this.passwordPattern.test(this.password)) {
                    ok = "密码为6-20位数字字母特殊符号";
                }
                return ok;
            },
            selectOtherAccount() {
                if (this.accountColumns.length > 0) {
                    this.showPicker = true;
                } else {
                    Toast('当前没有其他账号');
                }

            },
            pickAccount() {
                this.showPicker = false;
                let user = this.userList[this.currentIndex];
                this.account = user.u;
                this.password = crypto.decrypt(user.p);
            },
            onChange(picker, value, index) {
                this.currentIndex = index;
            },
            toForgetPage() {
                this.$router.push({path: '/h5/reset'});
            },
            toRegisterPage() {
                this.$router.push({path: '/h5/register'});
            },
            handlerRememberPassword() {
                localStorage.setItem('rememberPassword', this.rememberPassword);

                this.userList = JSON.parse(localStorage.getItem('userList')) || [];
                let has = false;
                let index = -1;
                this.userList.forEach((v, i) => {
                    if (v.u === this.account) {
                        has = true;
                        index = i;
                    }
                })

                let password = crypto.encrypt(this.password);
                localStorage.setItem('username', this.account);
                localStorage.setItem('password', password);

                if (this.rememberPassword) {
                    if (!has) {
                        let item = {
                            u: this.account,
                            p: password,
                        }
                        this.userList.push(item);
                        localStorage.setItem('userList', JSON.stringify(this.userList));
                    }
                } else {
                    if (has) {
                        this.userList.splice(index, 1);
                        localStorage.setItem('userList', JSON.stringify(this.userList));
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .bg-img {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        background-color: #f8f8f8;
        background-image: url(../assets/main-bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .main-page {
        max-width: 400px;
        margin: 0 auto;
    }

    .main-border {
        max-width: 500px;
        text-align: center;
        width: 90%;
        padding: 10px 20px;
        box-sizing: border-box;
        margin: 30px auto 0;
        /*border: 1px solid #eceaea;
        background-color: #ffffff;
        box-shadow: 2px 2px 10px 3px rgba(0,0,0,.1);*/
    }

    .main-border .box-button {
        margin: 15px 3px 0;
    }

    .main-border .box-button .text-button {
        font-size: 0.8rem;
        margin-top: 0.8rem;
        color: #888888;
        text-align: right;
    }

    .van-field {
        outline: 1px #dbdbdb solid;
        border-radius: 3px;
    }

    .van-field:focus-within {
        outline: 2px #7a69be solid;
    }

</style>
