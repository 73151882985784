<template>
    <div class="main-dialog">
        <div class="main-dialog-title">
            <div @click="back" style="width: 50px;height: 50px;display: flex;align-items: center;justify-content: center; position: absolute;">
                <van-icon name="arrow-left" size="20" color="#888888"/>
            </div>
            <div style="font-size: 16px;">
                联系客服
            </div>
        </div>

        <div class="main-dialog-body">
            <div class="kf-item">
                <img src="../assets/qq.png" style="width: 20px;height: 20px;border-radius: 3px;">
                <div style="flex: 1; margin-left: 10px;text-align: left">qq客服：3758985932</div>
                <div class="sec-button">复制</div>
            </div>
            <!--<div class="kf-item">
                <img src="../assets/wx.png" style="width: 20px;height: 20px;border-radius: 3px;">
                <div style="flex: 1; margin-left: 10px;text-align: left">微信客服：wxkf123456</div>
                <div class="sec-button">复制</div>
            </div>-->

            <div style="margin-top: 15px;display: flex;flex-direction: column;align-items: center">
                <img src="../assets/gzh.jpg" style="width: 90px;height: 90px;"/>
                <span style="margin-top: 10px;font-size: 14px;color: #333">扫码关注更多精彩活动</span>
            </div>

        </div>
    </div>
</template>

<script>

    export default {
        name: "Kf",
        data() {
            return {
            }
        },
        mounted() {
            this.from = localStorage.getItem('from');
            this.addInputBlur();
        },
        methods: {
            back() {
                this.$parent.$parent.show('mainMenu')
            },

        }
    }
</script>

<style scoped>
    .main-dialog {
        height: 300px;
        padding: 0 5px;
    }
    .main-dialog-title {
        position: relative;
        height: 50px;
        line-height: 50px;
        color: #333333;
    }
    .main-dialog-body {
        padding: 0 20px;
        margin-top: 10px;
    }
    .kf-item {
        width: 100%;
        display: flex;
        font-size: 13px;
        color: #444444;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
    }
    .sec-button {
        font-size: 12px;
        height: 23px;
        line-height: 23px;
        background-color: #7a69be;
        color: #ffffff;
        width: 40px;
        text-align: center;
        border-radius: 3px;
        justify-self: right;
    }
</style>