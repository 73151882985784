<template>
    <div class="bg-img">
        <div class="main-page">
            <div style="width: 100%;text-align: left;margin: 90px 20px 0;">
                <img src="../../assets/logo/logo-main.png" style="width: 150px;">
            </div>

            <div class="main-border">

                <div style="height: 40px; display: flex;align-items: flex-end;color: #444444;margin-bottom: 15px;font-size: 14px;">
                    <div  @click="pageActive = 1" style="height: 40px; cursor: pointer;display: flex;flex-direction: column;justify-content: space-between; align-items: center">
                        <span :style="pageActive === 1 && 'color: #7a69be;font-weight:bold;'">账号注册</span>
                        <div style="width: 30px;height: 4px;" :style="pageActive === 1 && 'background-color: #7a69be;'"></div>
                    </div>

                    <div  @click="pageActive = 2" style="margin-left: 20px;height: 40px;display: flex;flex-direction: column;justify-content: space-between;cursor: pointer;align-items: center">
                        <span :style="pageActive === 2 && 'color: #7a69be;font-weight:bold;'">手机号码注册</span>
                        <div style="width: 30px;height: 4px;" :style="pageActive === 2 && 'background-color: #7a69be;'"></div>
                    </div>
                </div>

                <van-form @submit="onSubmit">

                    <div v-if="pageActive === 1">
                        <van-field label="账号" label-width="60" style="color: #F18F25" placeholder="请输入账号"
                                   class="border-input" v-model="z_account"
                                   clearable>
                        </van-field>

                        <van-field label="登录密码" label-width="60" type="password" style="color: #F18F25;margin-top: 10px;"
                                   placeholder="请输入登录密码" class="border-input"
                                   v-model="z_password" clearable/>

                        <van-field label="确认密码" label-width="60" type="password" style="color: #F18F25;margin-top: 10px;"
                                   placeholder="请再次输入登录密码" class="border-input"
                                   v-model="z_password2" clearable/>

                    </div>

                    <div v-else>
                        <van-field label="手机号码" label-width="60" style="color: #F18F25" placeholder="请输入手机号码"
                                   class="border-input" v-model="account"
                                   clearable>
                        </van-field>

                        <van-field label="登录密码" label-width="60" type="password" style="color: #F18F25;margin-top: 10px;"
                                   placeholder="请输入登录密码" class="border-input"
                                   v-model="password" clearable/>

                        <van-field label="确认密码" label-width="60" type="password" style="color: #F18F25;margin-top: 10px;"
                                   placeholder="请再次输入登录密码" class="border-input"
                                   v-model="password2" clearable/>

                        <van-field label="验证码" label-width="60" type="number" maxlength="6"
                                   style="color: #F18F25;margin-top: 10px;" placeholder="验证码" class="border-input"
                                   v-model="smsCode">

                            <template #button>
                                <van-count-down
                                    v-show="showCountDown"
                                    ref="countDown"
                                    :time="60000"
                                    :auto-start="false"
                                    format="重新发送(ss秒)"
                                    style="font-size: 12px;color: #888888"
                                    @finish="finish"
                                />
                                <span style="color: #7a69be;font-size: 12px;user-select: none;cursor: pointer;"
                                      v-show="!showCountDown" @click="sendCode">发送验证码</span>
                            </template>
                        </van-field>
                    </div>


                    <div style="display: flex;justify-content: flex-start;align-items: center;margin-top: 10px;font-size: 12px;">
                        <van-checkbox v-model="agree" icon-size="13px" shape="square" checked-color="#7a69be">
                            <span style="color: #333333;user-select: none;cursor: pointer;">我已阅读并同意</span>
                        </van-checkbox>
                        <span style="color: #7a69be;user-select: none;cursor: pointer;"
                              @click="openUserAgreement">《用户协议》</span>
                    </div>

                    <div class="box-button">
                        <span @click="back"
                              style="color: #7a69be;user-select: none;cursor: pointer;font-size: 13px;">返回登录</span>
                        <van-button type="info" round block native-type="submit" :loading="loading"
                                    style="height: 35px;font-weight: bold;width: 150px"
                                    loading-text="注册中" color="#7a69be">
                            注 册
                        </van-button>
                    </div>
                </van-form>

                <van-popup v-model="showDialog" position="center" :lock-scroll="false" style="background-color: transparent"
                           :style="{ width: '90%', height: '70%' }">
                    <div style="height: 100%;background-color: #FFFFFF;border-radius: 5px;overflow: hidden">
                        <div style="padding: 10px;height: calc(100% - 70px);">
                            <iframe v-show="true" allowfullscreen ref="dialogIframe" style="margin:0;padding:0"
                                    :src="userAgreeUrl" frameborder="no" border="0"
                                    marginwidth="0" marginheight="0" scrolling="yes" width="100%" height="100%"></iframe>
                        </div>
                        <div @click="showDialog = false"
                             style="height: 50px;display: flex;align-items: center;justify-content: center;border-top: 0.5px solid #DDDDDD">
                            <span style="font-size: 13px;color: #5A6EFC">关闭</span>
                        </div>
                    </div>
                </van-popup>

            </div>
        </div>
    </div>
</template>

<script>
    import {request} from '@/api/request'
    import crypto from '@/utils/crypto.js';

    import {Toast, Dialog} from 'vant'

    export default {
        name: 'login',
        data() {
            return {
                account: '',
                password: '',
                password2: '',
                smsCode: '',

                z_accountPattern: /^[a-zA-Z0-9_]{6,12}$/,
                z_account: '',
                z_password: '',
                z_password2: '',

                showCountDown: false,
                agree: false,
                accountPattern: /^1[0-9]{10}$/,
                passwordPattern: /^(?=.*\d)(?=.*[a-zA-Z]).{6,20}$/,
                showDialog: false,
                loading: false,
                channelId: '',
                gameId: '',
                from: '',
                pageActive: 1,
                userAgreeUrl: 'https://game.daoyigame.com/static/res/user.html',
            };
        },
        mounted() {
            this.channelId = localStorage.getItem('channelId');
            this.gameId = localStorage.getItem('gameId');
        },
        methods: {
            back() {
                this.$router.back();
            },
            openUserAgreement() {
                //this.showDialog = true;
                window.open(this.userAgreeUrl, '');
            },
            finish() {
                this.showCountDown = false;
                this.$refs.countDown.reset();
            },
            sendCode() {
                let ok = this.checkUserName();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }
                this.showLoading = true;
                let options = {
                    url: "/h5/sendSms",
                    data: {
                        type: 1,
                        phone: this.account
                    }
                };
                request(options).then(res => {
                    console.log(res);
                    this.showLoading = false;
                    if (res.data.success) {
                        this.showCountDown = true;
                        this.$refs.countDown.start();
                        Toast('验证码发送成功');
                    } else {
                        Toast(res.data.error.message);
                    }
                });
            },
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }
                this.loading = true;
                let data;
                if (this.pageActive === 1) {
                    data = {
                        gameId: this.gameId,
                        username: this.z_account,
                        password: crypto.encrypt(this.z_password),
                        registerType: 1
                    }
                }
                else {
                    data = {
                        gameId: this.gameId,
                        username: this.account,
                        password: crypto.encrypt(this.password),
                        smsCode: this.smsCode,
                        registerType: 2
                    }
                }

                let options = {
                    url: "/h5/register",
                    data: data
                };
                request(options).then(res => {
                    this.loading = false;
                    if (res.data.success) {
                        Dialog.alert({
                            title: '提示',
                            message: '用户注册成功',
                            messageAlign: 'center'
                        }).then(() => {
                            this.back();
                        });
                    } else {
                        Dialog.alert({
                            title: '提示',
                            message: res.data.error.message,
                            messageAlign: 'center'
                        }).then(() => {
                        });
                    }
                });
            },
            checkUserName() {
                if (!this.account) {
                    return "请输入手机号码"
                }
                if (!this.accountPattern.test(this.account)) {
                    return "手机号码格式错误";
                }
                return 'ok';
            },
            checkForm() {
                if (this.pageActive === 1) {
                    if (!this.z_account) {
                        return "请输入账号"
                    }
                    if (!this.z_password) {
                        return "请输入密码";
                    }
                    if (!this.z_password2) {
                        return "请再次输入密码";
                    }
                    if (!this.z_accountPattern.test(this.z_account)) {
                        return "帐号为6-12位数字字母下划线";
                    }
                    if (!this.passwordPattern.test(this.z_password)) {
                        return "密码为6-20位同时包含数字字母";
                    }
                    if (this.z_password !== this.z_password2) {
                        return "两次密码输入不一致，请重新输入";
                    }
                    if (!this.agree) {
                        return '帐号注册需要同意用户协议';
                    }
                    return 'ok';
                }
                else {
                    if (!this.account) {
                        return "请输入手机号码"
                    }
                    if (!this.password) {
                        return "请输入密码";
                    }
                    if (!this.password2) {
                        return "请再次输入密码";
                    }
                    if (!this.smsCode) {
                        return "请输入手机验证码";
                    }
                    if (!this.accountPattern.test(this.account)) {
                        return "手机号码格式错误";
                    }
                    if (!this.passwordPattern.test(this.password)) {
                        return "密码长度为6到20且同时包含数字字母";
                    }
                    if (this.password !== this.password2) {
                        return "两次密码输入不一致，请重新输入";
                    }
                    if (!this.agree) {
                        return '帐号注册需要同意用户协议';
                    }
                    return 'ok';
                }

            },
        }
    }
</script>

<style scoped>
    .bg-img {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        background-color: #f8f8f8;
        background-image: url(../../assets/main-bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .main-page {
        max-width: 400px;
        margin: 0 auto;
    }

    .main-border {
        max-width: 500px;
        text-align: center;
        width: 90%;
        padding: 10px 20px;
        box-sizing: border-box;
        margin: 30px auto 0;
    }

    .box-button {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
    }

    .van-field {
        outline: 1px #dbdbdb solid;
        border-radius: 3px;
    }

    .van-field:focus-within {
        outline: 2px #7a69be solid;
    }

</style>
